// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "df_y0";
export var servicesDevExpertiseCompany__card = "df_y1";
export var servicesDevExpertiseCompany__emoji = "df_y3";
export var servicesDevExpertiseCompany__maxWidth140 = "df_y5";
export var servicesDevExpertiseCompany__maxWidth180 = "df_y6";
export var servicesDevExpertiseCompany__maxWidth190 = "df_y7";
export var servicesDevExpertiseCompany__maxWidth270 = "df_y8";
export var servicesDevExpertiseCompany__maxWidth310 = "df_y9";
export var servicesDevExpertiseCompany__text = "df_y4";
export var servicesDevExpertiseCompany__title = "df_y2";