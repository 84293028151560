// extracted by mini-css-extract-plugin
export var sprite3DIcons = "cc_pr";
export var sprite3DIcons__sprite = "cc_ps";
export var sprite3DIcons__sprite3DIcon_1 = "cc_pt";
export var sprite3DIcons__sprite3DIcon_10 = "cc_pF";
export var sprite3DIcons__sprite3DIcon_11 = "cc_pG";
export var sprite3DIcons__sprite3DIcon_12 = "cc_pH";
export var sprite3DIcons__sprite3DIcon_13 = "cc_pJ";
export var sprite3DIcons__sprite3DIcon_14 = "cc_pK";
export var sprite3DIcons__sprite3DIcon_15 = "cc_pL";
export var sprite3DIcons__sprite3DIcon_16 = "cc_pM";
export var sprite3DIcons__sprite3DIcon_17 = "cc_pN";
export var sprite3DIcons__sprite3DIcon_18 = "cc_pP";
export var sprite3DIcons__sprite3DIcon_19 = "cc_pQ";
export var sprite3DIcons__sprite3DIcon_2 = "cc_pv";
export var sprite3DIcons__sprite3DIcon_20 = "cc_pR";
export var sprite3DIcons__sprite3DIcon_21 = "cc_pS";
export var sprite3DIcons__sprite3DIcon_22 = "cc_pT";
export var sprite3DIcons__sprite3DIcon_3 = "cc_pw";
export var sprite3DIcons__sprite3DIcon_4 = "cc_px";
export var sprite3DIcons__sprite3DIcon_5 = "cc_py";
export var sprite3DIcons__sprite3DIcon_6 = "cc_pz";
export var sprite3DIcons__sprite3DIcon_7 = "cc_pB";
export var sprite3DIcons__sprite3DIcon_8 = "cc_pC";
export var sprite3DIcons__sprite3DIcon_9 = "cc_pD";