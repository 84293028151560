// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f6_Qr";
export var servicesTechStack__itemWrapper = "f6_Qq";
export var servicesTechStack__name = "f6_Qs";
export var servicesTechStack__name_chromedevtools = "f6_QB";
export var servicesTechStack__name_digitalocean = "f6_Qy";
export var servicesTechStack__name_elasticsearch = "f6_Qw";
export var servicesTechStack__name_gitlab = "f6_Qz";
export var servicesTechStack__name_googleCloudPlatform = "f6_QD";
export var servicesTechStack__name_googleDocs = "f6_QC";
export var servicesTechStack__name_googleanalytics = "f6_Qt";
export var servicesTechStack__name_prometheus = "f6_Qv";
export var servicesTechStack__name_ror = "f6_Qx";