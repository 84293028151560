// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "H_gJ";
export var insuranceBusinessesSection = "H_gG";
export var insuranceCaseStudySection = "H_gL";
export var insuranceClientQuotesSection = "H_gV";
export var insuranceDevExpertiseSection = "H_gR";
export var insuranceDevServicesIconSection = "H_gF";
export var insuranceOurAchievementsSection = "H_gT";
export var insuranceProcessSection = "H_gH";
export var insuranceProjLogoSection = "H_gQ";
export var insuranceRequirementsSection = "H_gM";
export var insuranceSkilledSection = "H_gS";
export var insuranceSuccessStoriesSection = "H_gN";
export var insuranceTechStackSection = "H_gK";
export var insuranceWhyHireSection = "H_gP";